import React, { useState, useEffect, useContext } from "react"
import { PreferredLanguageContext } from "../../lib/main-context"
import { SIGNIN, SALLIE_RESET_PASSWORD, CONTINUE } from "../../constants/SignInTranslation"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"

import { Row, Col, Form } from "react-bootstrap"
import Sallie from "../Sallie"
import LabelledInput from "../LabelledInput"
import DisabledButton from "../StyledComponents/DisabledButton"

import { ResetPw1Schema } from "./validation"
import { sendResetPwEmail } from "../../api/helpReq"

import emailToLowerCase from "../../lib/emailToLowerCase"

const ResetPassword1 = () => {
  const { language } = useContext(PreferredLanguageContext);

  const [isLoading, setIsLoading] = useState(false)
  const [sallieMsg, setSallieMsg] = useState(SALLIE_RESET_PASSWORD[language].reset_password)
  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(ResetPw1Schema)
  })
  const { touched } = formState

  useEffect(() => {
    setSallieMsg(SALLIE_RESET_PASSWORD[language].reset_password);
  }, [language])

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const formData = emailToLowerCase(data);
      await sendResetPwEmail(formData);
      setSallieMsg(
        `${SALLIE_RESET_PASSWORD[language].email_sent} ${formData.email}.`
      );
    } catch (error) {
      console.error("Error sending password reset email:", error);

      if (error.response && error.response.status === 404) {
        // Handle 404 Not Found error
        setSallieMsg(SALLIE_RESET_PASSWORD[language].no_account);
      } else {
        // Handle other errors (e.g., 500 Internal Server Error)
        setSallieMsg(SALLIE_RESET_PASSWORD[language].error);
      }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <Sallie text={sallieMsg} />
      <Row className="d-flex justify-content-center">
        <Col xs={10} md={6}>
          <Form
            autoComplete="on"
            method="POST"
            className="mt-2"
            style={{ margin: "0 auto" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <LabelledInput
              labelAs="h6"
              name="email"
              type="email"
              label={SIGNIN[language].email}
              autoComplete="email"
              inputRef={register}
              errors={errors}
              touched={touched}
            />
            <DisabledButton
              disabled={isLoading}
              className="w-100"
              type="submit"
              variant="outline-primary"
            >
              {CONTINUE[language]}
            </DisabledButton>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ResetPassword1
