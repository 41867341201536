import React, { useState, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { SIGNIN } from "../../constants/SignInTranslation";

// bootstrap
import Form from "react-bootstrap/Form";

// hooks
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useHistory, useLocation, Link, Redirect } from "react-router-dom";

// custom components
import { Row, Col } from "react-bootstrap";
import SubmitButton from "../StyledComponents/SubmitButton";
import SmLinkButton from "../StyledComponents/smLinkButton";
import LabelledInput from "../LabelledInput";

// api
import { signIn } from "../../api/auth";
import { SignInSchema } from "./validation";

// lib
import emailToLowerCase from "../../lib/emailToLowerCase";

const SignIn = ({ setUser, user, setPatientList }) => {
  const history = useHistory();
  const { language } = useContext(PreferredLanguageContext);

  const [isLoading, setIsLoading] = useState(false);
  const [credentialError, setCredentialError] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  // create a hook for the form, passing in an object of configs

  const { register, handleSubmit, errors, formState, setError } = useForm({
    resolver: yupResolver(SignInSchema),
  });
  const { touched } = formState;
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const formData = emailToLowerCase(data);
      const res = await signIn(formData);
      setUser(res.data);
      // We need a way to set patient state in addition to user state around login time
      if (res.data.userType === "caregiver") history.replace("/patientControl");
      else history.replace(from);
    } catch (err) {
      if (err.message.includes("409")) {
        alert("You are already logged in, please refresh the page.");
        setIsLoading(false);
      } else {
        setError("email", {
          type: "badCredentials",
          message: "Email or Password is incorrect",
        });
        setError("password",
          {
            type: "badCredentials",
            message: "",
          });
        setCredentialError(true);
        setIsLoading(false);
      }
    }
  };

  if (user) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <Row className="d-flex justify-content-center">
      <Col xs={10} md={6}>
        <Form
          autoComplete="on"
          method="POST"
          className="mt-2"
          style={{ margin: "0 auto" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <LabelledInput
            labelAs="h6"
            label={SIGNIN[language].email}
            name="email"
            type="email"
            autoComplete="email"
            inputRef={register}
            errors={errors}
            touched={touched}
          />

          <LabelledInput
            labelAs="h6"
            label={SIGNIN[language].password}
            name="password"
            type="password"
            autoComplete="current-password"
            inputRef={register}
            errors={errors}
            touched={touched}
          />

          <SubmitButton disabled={isLoading} className="w-100" type="submit">
            {SIGNIN[language].login}
          </SubmitButton>
          <Form.Row>
            {credentialError ? (
              <div>
                <div style={{ marginBottom: "15px" }}>
                  {SIGNIN[language].wrong_credentials}
                </div>
                <div>
                  {SIGNIN[language].forgot_password}
                  <SmLinkButton>
                    <Link to="/resetPassword1">{SIGNIN[language].here}</Link>
                  </SmLinkButton>
                </div>
                <div>
                  {SIGNIN[language].new_user}
                  <SmLinkButton>
                    <Link to="/register">{SIGNIN[language].here}</Link>
                  </SmLinkButton>
                </div>
              </div>
            ) : (
              <Link to="/register">
                {SIGNIN[language].no_account}
              </Link>
            )}
          </Form.Row>
        </Form>
      </Col>
    </Row>
  );
};

export default SignIn;
