import React from "react";

export const ThemeContext = React.createContext({
  dark: false,
  toggleTheme: () => { },
});

export const PatientContext = React.createContext({
  patient: undefined,
});

export const InputVisibleContext = React.createContext({
  inputVisible: undefined,
});

export const SelectedPatientContext = React.createContext({
  selectedPatient: undefined,
  selectedPatientTopSymptoms: undefined,
  setSelectedPatient: () => { },
  setSelectedPatientTopSymptoms: () => { },
});

export const PreferredLanguageContext = React.createContext({
  language: 'english',
  setLanguage: () => { },
})